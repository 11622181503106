var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Composition d’un alliage: Calculations pour le métal connu")]),_c('v-form',[_c('h3',{staticClass:"mb-4 mt-8"},[_vm._v("Tableau de données")]),_c('v-textarea',{staticClass:"mb-n3",attrs:{"label":"Légende","outlined":"","rows":"1","placeholder":"Rédigez une légende pour le tableau des constantes ici."},model:{value:(_vm.inputs.data_table_caption),callback:function ($$v) {_vm.$set(_vm.inputs, "data_table_caption", $$v)},expression:"inputs.data_table_caption"}}),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" ")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"},attrs:{"colspan":"2"}},[_vm._v("Métal connu")])]),_c('tr',[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Unités")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Essai 1")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Essai 2")])])]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Identité")]),_c('td',[_vm._v(" ")]),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('v-select',{attrs:{"items":['Zn', 'Mg'],"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}],null,true),model:{value:(_vm.inputs[("trial" + i + "_identity")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_identity"), $$v)},expression:"inputs[`trial${i}_identity`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Masse")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['mass_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'mass_units', $$v)},expression:"inputs['mass_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_mass")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_mass"), $$v)},expression:"inputs[`trial${i}_mass`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$p_i$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['initialPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'initialPressure_units', $$v)},expression:"inputs['initialPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_initialPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_initialPressure"), $$v)},expression:"inputs[`trial${i}_initialPressure`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$p_f$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['finalPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'finalPressure_units', $$v)},expression:"inputs['finalPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_finalPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_finalPressure"), $$v)},expression:"inputs[`trial${i}_finalPressure`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Température ambiante")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['roomTemperature_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'roomTemperature_units', $$v)},expression:"inputs['roomTemperature_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_roomTemperature")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_roomTemperature"), $$v)},expression:"inputs[`trial${i}_roomTemperature`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Masse du flacon vide")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['massEmptyFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'massEmptyFlask_units', $$v)},expression:"inputs['massEmptyFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_massEmptyFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_massEmptyFlask"), $$v)},expression:"inputs[`trial${i}_massEmptyFlask`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Masse du flacon rempli")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['massFilledFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'massFilledFlask_units', $$v)},expression:"inputs['massFilledFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_massFilledFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_massFilledFlask"), $$v)},expression:"inputs[`trial${i}_massFilledFlask`]"}})],1)})],2)]),_c('h3',{staticClass:"mb-4 mt-8"},[_vm._v("Calculations: Métal connu")]),_c('v-textarea',{staticClass:"mb-n3",attrs:{"label":"Légende","outlined":"","rows":"1","placeholder":"Rédigez une légende pour le tableau des valeurs calculées ici"},model:{value:(_vm.inputs.calculations_table_caption),callback:function ($$v) {_vm.$set(_vm.inputs, "calculations_table_caption", $$v)},expression:"inputs.calculations_table_caption"}}),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Unités")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Essai 1")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Essai 2")])])]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Quantité de matière du métal")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfMetal_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfMetal_units', $$v)},expression:"inputs['molesOfMetal_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfMetal")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfMetal"), $$v)},expression:"inputs[`trial${i}_molesOfMetal`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Quantité de matière théorique de l’hydrogène")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfHydrogenGasExpected_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfHydrogenGasExpected_units', $$v)},expression:"inputs['molesOfHydrogenGasExpected_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfHydrogenGasExpected")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfHydrogenGasExpected"), $$v)},expression:"inputs[`trial${i}_molesOfHydrogenGasExpected`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Volume du flacon")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['volumeOfFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'volumeOfFlask_units', $$v)},expression:"inputs['volumeOfFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_volumeOfFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_volumeOfFlask"), $$v)},expression:"inputs[`trial${i}_volumeOfFlask`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Volume du flacon disponible")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['volumeAvailable_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'volumeAvailable_units', $$v)},expression:"inputs['volumeAvailable_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_volumeAvailable")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_volumeAvailable"), $$v)},expression:"inputs[`trial${i}_volumeAvailable`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\Delta p$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['changeInPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'changeInPressure_units', $$v)},expression:"inputs['changeInPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_changeInPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_changeInPressure"), $$v)},expression:"inputs[`trial${i}_changeInPressure`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Quantité de matière expérimentale de l’hydrogène")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfHydrogenGasActual_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfHydrogenGasActual_units', $$v)},expression:"inputs['molesOfHydrogenGasActual_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfHydrogenGasActual")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfHydrogenGasActual"), $$v)},expression:"inputs[`trial${i}_molesOfHydrogenGasActual`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Rendement")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['percentYield_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'percentYield_units', $$v)},expression:"inputs['percentYield_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_percentYield")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_percentYield"), $$v)},expression:"inputs[`trial${i}_percentYield`]"}})],1)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }